import { reactive, ref } from "vue"
import axios from 'axios'
import NProgress from 'nprogress';
import router from "@/router";


export const G = {
    // ajaxBaseUrl: `http://localhost:8000`,
    ajaxBaseUrl: `https://gpsbackend.onetrack.az`,

    mediaURL: `http://localhost:8000/media/`,

    main_host: 'onetrack.az',
    csrfName: "",
    csrfHash: "",
    token: "",
    currentRoute: "",
    current: reactive({
        page: "",
        data: {}
    }),
    columnMaxConstraints: 3,

    defaultPage: "pages/Home",

    projectName: "ONETRACK",
    projectDescription: "Positioning Systems",
    footerPowered: '© <a href="https://gps.onetrack.az" class="link-info opacity-50 me-2" target="_blank">gps.onetrack.az</a> <small>Started At 09.Mar.2023</small>',
    footerDescription: 'onetrack.az',

    rowsPerPageOptions: [5, 10, 25, 50, 100, 500],

    modal: reactive({
        page: null,
        data: null
    }),

    uploadedFileSize: 3000000,

    showFilterAfterLength: 15,

    toastLifeTime: 2000,

    toast: reactive({
        ref: null,
        class: {
            success: 'fas fa-check',
            error: 'fas fa-ban',
            info: 'fas fa-info-circle',
            warn: 'fas fa-exclamation-triangle',
        },
        obj: {},
        init: (opt) => {
            G.toast.obj = {
                clean: (opt.clean !== undefined ? opt.clean : true),
                severity: G.toast.obj.severity !== undefined ? G.toast.obj.severity : 'success',
                position: opt.position !== undefined ? opt.position : 'center',
                msg: opt.msg !== undefined ? opt.msg : '',
                title: opt.title !== undefined ? opt.title : '',
                life: (opt.type === 'confirm') ? 0 : (opt.life !== undefined ? opt.life : G.toastLifeTime),
                type: opt.type !== undefined ? opt.type : 'message',
                group: opt.group !== undefined ? opt.group : 'mainToast',

                yesButtonClass: opt.yesButtonClass !== undefined ? opt.yesButtonClass : undefined,
                yesIcon: opt.yesIcon !== undefined ? opt.yesIcon : undefined,
                yesTitle: opt.yesTitle !== undefined ? opt.yesTitle : 'yes',
                yes: opt.yes !== undefined ? opt.yes : (e) => { },
                noButtonClass: opt.noButtonClass !== undefined ? opt.noButtonClass : undefined,
                noIcon: opt.noIcon !== undefined ? opt.noIcon : undefined,
                noTitle: opt.noTitle !== undefined ? opt.noTitle : 'no',
                no: opt.no !== undefined ? opt.no : (e) => { G.toast.ref.removeGroup('mainToast'); },
            };
        },
        notify: (opt) => {
            if (opt instanceof Object === false) {
                G.toast.init({ msg: opt });
                G.toast.obj.msg = opt;
                if (G.toast.obj.severity == 'success')
                    G.toast.obj.position = 'bottom-center';
                else
                    G.toast.obj.position = 'center';
            }
            else {
                G.toast.init(opt);
            }

            if (G.toast.obj.type != 'confirm' || G.toast.obj.clean)
                G.toast.ref.removeGroup('mainToast');

            return G.toast.ref.add({
                severity: G.toast.obj.severity,
                type: G.toast.obj.type,
                title: G.toast.obj.title,
                detail: G.toast.obj.msg,
                life: G.toast.obj.life,
                group: G.toast.obj.group,

                yesButtonClass: G.toast.obj.yesButtonClass,
                yesIcon: G.toast.obj.yesIcon,
                yesTitle: G.toast.obj.yesTitle,
                yes: (e) => { G.toast.obj.yes(e); G.toast.ref.removeGroup('mainToast'); },
                noButtonClass: G.toast.obj.noButtonClass,
                noIcon: G.toast.obj.noIcon,
                noTitle: G.toast.obj.noTitle,
                no: (e) => { G.toast.obj.no(e); G.toast.ref.removeGroup('mainToast'); },
            });
        },
        success: (opt) => {
            G.toast.obj.severity = 'success';
            return G.toast.notify(opt);
        },
        error: (opt) => {
            G.toast.obj.severity = 'error';
            return G.toast.notify(opt);
        },
        info: (opt) => {
            G.toast.obj.severity = 'info';
            return G.toast.notify(opt);
        },
        warn: (opt) => {
            G.toast.obj.severity = 'warn';
            return G.toast.notify(opt);
        },
    }),

    atoast: {
        ref: null,
        class: {
            success: 'fas fa-check',
            error: 'fas fa-ban',
            info: 'fas fa-info-circle',
            warn: 'fas fa-exclamation-triangle',
        },
        obj: {},
        init: (opt) => {
            G.atoast.obj = {
                clean: (opt.clean !== undefined ? opt.clean : true),
                severity: G.atoast.obj.severity !== undefined ? G.atoast.obj.severity : 'success',
                position: opt.position !== undefined ? opt.position : 'center',
                msg: opt.msg !== undefined ? opt.msg : '',
                title: opt.title !== undefined ? opt.title : '',
                life: (opt.type === 'confirm') ? 0 : (opt.life !== undefined ? opt.life : G.toastLifeTime),
                type: opt.type !== undefined ? opt.type : 'message',
                group: opt.group !== undefined ? opt.group : 'appToast',

                yesButtonClass: opt.yesButtonClass !== undefined ? opt.yesButtonClass : undefined,
                yesIcon: opt.yesIcon !== undefined ? opt.yesIcon : undefined,
                yesTitle: opt.yesTitle !== undefined ? opt.yesTitle : 'yes',
                yes: opt.yes !== undefined ? opt.yes : (e) => { },
                noButtonClass: opt.noButtonClass !== undefined ? opt.noButtonClass : undefined,
                noIcon: opt.noIcon !== undefined ? opt.noIcon : undefined,
                noTitle: opt.noTitle !== undefined ? opt.noTitle : 'no',
                no: opt.no !== undefined ? opt.no : (e) => { G.atoast.ref.removeGroup('appToast'); },
            };
        },
        notify: (opt) => {
            if (opt instanceof Object === false) {
                G.atoast.init({ msg: opt });
                G.atoast.obj.msg = opt;
            }
            else
                G.atoast.init(opt);

            if (G.atoast.obj.clean)
                G.atoast.ref.removeAllGroups();//('appToast');

            return G.atoast.ref.add({
                severity: G.atoast.obj.severity,
                type: G.atoast.obj.type,
                title: G.atoast.obj.title,
                detail: G.atoast.obj.msg,
                life: G.atoast.obj.life,
                group: G.atoast.obj.group,

                yesButtonClass: G.atoast.obj.yesButtonClass,
                yesIcon: G.atoast.obj.yesIcon,
                yesTitle: G.atoast.obj.yesTitle,
                yes: (e) => { G.atoast.obj.yes(e); G.atoast.ref.removeGroup('appToast'); },
                noButtonClass: G.atoast.obj.noButtonClass,
                noIcon: G.atoast.obj.noIcon,
                noTitle: G.atoast.obj.noTitle,
                no: (e) => { G.atoast.obj.no(e); G.atoast.ref.removeGroup('appToast'); },
            });
        },
        success: (opt) => {
            G.atoast.obj.severity = 'success';
            return G.atoast.notify(opt);
        },
        error: (opt) => {
            G.atoast.obj.severity = 'error';
            return G.atoast.notify(opt);
        },
        info: (opt) => {
            G.atoast.obj.severity = 'info';
            return G.atoast.notify(opt);
        },
        warn: (opt) => {
            G.atoast.obj.severity = 'warn';
            return G.atoast.notify(opt);
        },
    },

    uniqueId: () => {
        if (arguments[0]) var symlen = arguments[0];
        else var symlen = 4;
        var symsize, pos, id = '', sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        symsize = sym.length;
        for (var i = 1; i <= symlen; i++) {
            pos = Math.floor(Math.random() * symsize);
            id += sym[pos];
        }
        return id;
    },

    showModal: (opt) => {
        G.modal.page = opt.page;
        G.modal.data = opt;
    },
    closeModal: () => {
        G.modal.page = null;
        G.modal.data = null;
    },

    modalFormValidate: (frm) => {
        let a = frm.currentTarget.closest('.dynamicModal').querySelector('form').querySelectorAll('.required');
        a.forEach((b) => {
            if (b.value == '') {
                b.classList.add('required');
            }
        });
    },

    isFormValid: (fdata) => {
        let validated = true;
        G.modal.data.table_columns.forEach((b) => {
            if (b.required !== undefined && b.required && (fdata[b.name] === undefined || fdata[b.name] == '')) {
                validated = false;
            }
        });
        return validated;
    },

    // capitalize: (txt) => {
    //     return txt
    //         .trim()
    //         .toLowerCase()
    //         .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    // },
    capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',

    strip: (str, sym) => str != '' ? str.replace(new RegExp(`^${sym}+`, 'i'), '').replace(new RegExp(`${sym}+$`, 'i'), '') : '',

    token_data: () => {
        if (G.hasClientData("AUTH_TOKEN"))
            return JSON.parse(atob(G.getClientData('AUTH_TOKEN').split('.')[0]));
        else
            return {};
    },

    userImage: '',

    user_roles: ref(['user', 'admin']),
    admin_roles: ref(['admin']),

    userPageAccess: ref([
        {
            role: "admin",
            permission: [
                {
                    route: "home",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "role",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "user",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "device_model",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "device_type",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "device",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "animal_type",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "animal",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "place",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "profile",
                    action: ["view", "add", "update", "remove"],
                },
                {
                    route: "notfound",
                    // action: ["view", "add", "update"],
                },
                {
                    route: "login",
                    // action: ["view", "add", "update"],
                },
            ]
        },
        {
            role: "user",
            permission: [
                {
                    route: "home",
                    action: ["view", "add", "update"],
                },
                {
                    route: "animal_type",
                    action: ["view", "add", "update"],
                },
                {
                    route: "animal",
                    action: ["view", "update"],
                },
                {
                    route: "place",
                    action: ["view", "add", "update"],
                },
                {
                    route: "profile",
                    action: ["view", "update"],
                },
                {
                    route: "notfound",
                    // action: ["view", "add", "update"],
                },
                {
                    route: "login",
                    // action: ["view", "add", "update"],
                },
                // {
                //     route: "role",
                //     action: ["view", "update"],
                // },
                // {
                //     route: "user",
                //     action: ["view", "update"],
                // },
            ]
        },
    ]),

    privateUserAccess: (perm) => {
        if (perm !== undefined && perm != "") {
            return G.userPageAccess.value.filter((f) => (f.role === G.token_data()['role'] && f.permission.filter((p) => (p.route == G.currentRoute.name && p.action && p.action.indexOf(perm) !== -1)).length)).length ? false : true;
        }
        else return G.user_roles.value.filter((e) => e === G.token_data()['role']).length ? false : true;
    },
    privateAdminAccess: () => {
        return G.admin_roles.value.filter((e) => e === G.token_data()['role']).length ? false : true;
    },

    getUserAccessToMenu: (route) => {
        if (route !== undefined && route != "") {
            return G.token_data() ? G.userPageAccess.value.filter((f) => f.role === G.token_data()['role'] && f.permission.filter((p) => p.route == route).length).length ? true : false : false;
        }
        else return false;
        // else return G.user_roles.value.filter((e) => e === G.token_data()['role']).length ? false : true;
    },

    formatLocale: (value) => {
        if (value)
            return value.toLocaleString("az-AZ", {
                style: "currency",
                currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    formatDateLocale: (value) => {
        if (value)
            return value.toLocaleDateString("az-AZ", {
                // style: "currency",
                // currency: "AZN",
                hour12: false,
                hourCycle: 'h24',
                // weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        else
            return value;
    },

    getErrorMessages: (data) => {
        let m = [];
        if (typeof data === 'object')
            data.forEach((s) =>
                Object.keys(s).map((k) => { if (k != 'type') m.push(`${k} : ${s[k]}`); })
            )
        else m[0] = data;

        return m.join("<br />");
    },


    hasClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) return true;
        else return false;
    },
    setClientData: async (name, value) => {
        if (typeof (Storage) !== 'undefined') {
            localStorage.setItem(name, value);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    getClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            return localStorage.getItem(name);
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    removeClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            localStorage.removeItem(name);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    is_mobile: () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return true;//"Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return true;//"Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;//"iOS";
        }

        return false;
    },
}

G.token = (G.hasClientData("AUTH_TOKEN")) ? G.getClientData("AUTH_TOKEN") : "";
// console.log(G.token);

axios.interceptors.request.use(req => {
    NProgress.start();
    return req;
}, (error) => {
    NProgress.done();
    return error;
});


axios.interceptors.response.use(response => {
    NProgress.done();
    if (response === undefined) {
        G.atoast.error('Server Connection Error !');
        return Promise.reject(response);
    }
    return response;
}, (error) => {
    NProgress.done();
    if (error.response === undefined) {
        G.atoast.error('Server Connection Error !');
        return Promise.reject(error);
    }
    else if (error.response.status == 0 && error.code == 'ERR_NETWORK') {
        G.atoast.error(`${error.message}`);
        return Promise.reject(error);
    }
    else if (error.response.status == 422 && error.code == 'ERR_BAD_REQUEST') {
        G.atoast.warn({ msg: G.getErrorMessages(error.response.data.detail) });
        return Promise.reject(error);
    }
    else if (error.response.status == 408) {
        G.setClientData("AUTH_TOKEN", '');
        G.closeModal();
        // router.push({ name: 'login', query: { redirect: window.location.pathname }, replace: true });
        router.go();
        G.atoast.error(error.response.data.detail);
        return Promise.reject(error);
    }
    else
        return error;
});


export const ajax = async (opt = null) => {
    var th = this;
    if (opt != null && opt.url !== undefined && opt.url != "")
        await axios({
            baseURL: G.ajaxBaseUrl,
            url: opt.url,
            method: opt.method || "get",
            data: opt.data || {},
            responseType: opt.responseType ? opt.responseType : null,
            headers: opt.header || {
                'X-ONETRACK-GPS': 'ONETRACK_GPS_data',
                'Content-Type': opt && opt.content_type ? opt.content_type : 'application/x-www-form-urlencoded',
                "Authorization": opt && opt.token ? `Bearer ${opt.token}` : G.getClientData("AUTH_TOKEN"),
            },
            withCredentials: false,
        }).then(async (response) => {
            if (opt.success !== undefined && opt.success != null)
                await opt.success(response);
        }).catch(async (error) => {
            let resp = error.response;
            if (opt.error !== undefined && opt.error != null && resp !== undefined)
                await opt.error(error);
        });
};




// ajax.defaults.url = !ajax.defaults.url.match(/^http/) ? `http://localhost:8000${ajax.defaults.url}` : ajax.defaults.url,

// Vue.filter('formatDate', function(value) {
//     if (value) {
//         return moment(String(value)).format('MM/DD/YYYY hh:mm')
//     }
// });


