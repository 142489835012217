import { createStore } from 'vuex'
import { G } from '@/_common';

export default createStore({
    state: () => {
        return {
            lang: 'az',
            translations: {},
            sidebarStatus: false,
            token: "",
            api: {
                login:                          '/login/',
                translations:                   '/lang/{lang}/translations',


                roles:                          '/role/{lang}/',
                roles_update:                   '/role/{lang}/{role_id}',

                users:                          '/users/{lang}/',
                users_update:                   '/users/{lang}/{user_id}',

                menu:                           '/menu/{lang}/',
                menu_update:                    '/menu/{lang}/{menu_id}',

                category:                       '/category/{lang}/',
                category_update:                '/category/{lang}/{category_id}',

                place:                          '/place/{lang}/',
                place_update:                   '/place/{lang}/{place_id}',

                device:                         '/device/{lang}/',
                device_update:                  '/device/{lang}/{device_id}',

                device_type:                    '/device_type/{lang}/',
                device_type_update:             '/device_type/{lang}/{device_type_id}',

                device_model:                   '/device_model/{lang}/',
                device_model_update:            '/device_model/{lang}/{device_model_id}',

                animal:                         '/animal/{lang}/',
                animal_update:                  '/animal/{lang}/{animal_id}',

                animal_type:                    '/animal_type/{lang}/',
                animal_type_update:             '/animal_type/{lang}/{animal_type_id}',

                positions:                      '/position/{lang}/get_positions',
                positions_by_device:            '/position/{lang}/get_positions/{device_id}',
                positions_by_date:              '/position/{lang}/get_positions/by/date/{device_id}/{date}',

            }
        }
    },
    getters: {
        getSidebarStatus: (state) => () => {
            if( G.getClientData("remm-left-sidebar") == "true" )
                state.sidebarStatus = true;
            return state.sidebarStatus;
        },
        setSidebarStatus: (state) => (value) => {
            bootstrap.Dropdown.clearMenus();
            G.setClientData("remm-left-sidebar", value);
            return state.sidebarStatus = value; //.find(todo => todo.id === id)
        },

        getLang: (state) => () => {
            return state.lang;
        },
        getTranslation: (state) => () => {
            return state.translations;
        },

        // ---------------------------------------------------------------------------------------------
        getLogin: (state) => () => {
            return state.api.login;
        },
        getTranslations: (state) => () => {
            return state.api.translations.replace('{lang}', state.lang);
        },


        // ---------------------------------------------------------------------------------------------
        getRoles: (state) => () => {
            return state.api.roles.replace('{lang}', state.lang);
        },
        getRolesUpdate: (state) => (role_id) => {
            return state.api.roles_update.replace('{lang}', state.lang).replace('{role_id}', role_id);
        },

        // ---------------------------------------------------------------------------------------------
        getUsers: (state) => () => {
            return state.api.users.replace('{lang}', state.lang);
        },
        getUsersUpdate: (state) => (user_id) => {
            return state.api.users_update.replace('{lang}', state.lang).replace('{user_id}', user_id);
        },


        // ---------------------------------------------------------------------------------------------
        getMenu: (state) => () => {
            return state.api.menu.replace('{lang}', state.lang);
        },
        getMenuUpdate: (state) => (menu_id) => {
            return state.api.menu_update.replace('{lang}', state.lang).replace('{menu_id}', menu_id);
        },

        // ---------------------------------------------------------------------------------------------
        getCategory: (state) => () => {
            return state.api.category.replace('{lang}', state.lang);
        },
        getCategoryUpdate: (state) => (category_id) => {
            return state.api.category_update.replace('{lang}', state.lang).replace('{category_id}', category_id);
        },

        // ---------------------------------------------------------------------------------------------
        getPlace: (state) => () => {
            return state.api.place.replace('{lang}', state.lang);
        },
        getPlaceUpdate: (state) => (place_id) => {
            return state.api.place_update.replace('{lang}', state.lang).replace('{place_id}', place_id);
        },

        // ---------------------------------------------------------------------------------------------
        getDevice: (state) => () => {
            return state.api.device.replace('{lang}', state.lang);
        },
        getDeviceUpdate: (state) => (device_id) => {
            return state.api.device_update.replace('{lang}', state.lang).replace('{device_id}', device_id);
        },

        // ---------------------------------------------------------------------------------------------
        getDeviceType: (state) => () => {
            return state.api.device_type.replace('{lang}', state.lang);
        },
        getDeviceTypeUpdate: (state) => (device_type_id) => {
            return state.api.device_type_update.replace('{lang}', state.lang).replace('{device_type_id}', device_type_id);
        },

        // ---------------------------------------------------------------------------------------------
        getDeviceModel: (state) => () => {
            return state.api.device_model.replace('{lang}', state.lang);
        },
        getDeviceModelUpdate: (state) => (device_model_id) => {
            return state.api.device_model_update.replace('{lang}', state.lang).replace('{device_model_id}', device_model_id);
        },

        // ---------------------------------------------------------------------------------------------
        getAnimal: (state) => () => {
            return state.api.animal.replace('{lang}', state.lang);
        },
        getAnimalUpdate: (state) => (animal_id) => {
            return state.api.animal_update.replace('{lang}', state.lang).replace('{animal_id}', animal_id);
        },

        // ---------------------------------------------------------------------------------------------
        getAnimalType: (state) => () => {
            return state.api.animal_type.replace('{lang}', state.lang);
        },
        getAnimalTypeUpdate: (state) => (animal_type_id) => {
            return state.api.animal_type_update.replace('{lang}', state.lang).replace('{animal_type_id}', animal_type_id);
        },

        // ---------------------------------------------------------------------------------------------
        getPositions: (state) => () => {
            return state.api.positions.replace('{lang}', state.lang);
        },
        getPositionsByDevice: (state) => (device_id) => {
            return state.api.positions_by_device.replace('{lang}', state.lang).replace('{device_id}', device_id);
        },
        getPositionsByDate: (state) => (device_id, date) => {
            return state.api.positions_by_date.replace('{lang}', state.lang).replace('{device_id}', device_id).replace('{date}', date);
        },

    },
    mutations: {
        setLang(state, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            state.lang = lang;
        },
        setTranslations(state, tr) {
            if (tr !== undefined)
                state.translations = tr;
        },
    },
    actions: {
        setLang({ commit }, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            commit('setLang', lang);
        },
        setTranslations({ commit }, tr) {
            if (tr !== undefined)
                commit('setTranslations', tr);
        },
    },
    modules: {
    }
})
